// If you don't want to use TypeScript you can delete this file!
import * as React from "react";
import { PageProps, Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { processSync } from "remark";
const LOADING = "LOADING";

type DataProps = {
  site: {
    buildTime: string;
  };
};

interface CounterState {
  counter: number;
  loading: string;
  xkcd: {
    month: string;
    num: number;
    link: string;
    year: string;
    news: string;
    safe_title: string;
    transcript: string;
    alt: string;
    img: string;
    title: string;
    day: string;
  };
}

const XKCD = props => {
  return (
    <figure className="gatsby-resp-image-figure">
      <h4>
        [{props.num}] {props.safe_title}
      </h4>
      <span className="gatsby-resp-image-wrapper">
        <a className="gatsby-resp-image-link" href={props.link}>
          <img
            className="gatsby-resp-image-image"
            src={props.img}
            alt={props.alt}
            loading="lazy"
            decoding="async"
          ></img>
        </a>
      </span>
      <figcaption className="gatsby-resp-image-figcaption">
        <p>
          <em className="img-caption">{props.alt}</em>
        </p>
      </figcaption>
    </figure>
  );
};

class App extends React.Component<{}, CounterState> {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      loading: LOADING,
      xkcd: {
        month: "",
        num: 0,
        link: "",
        year: "",
        news: "",
        safe_title: "",
        transcript: "",
        alt: "",
        img: "",
        title: "",
        day: "",
      },
    };

    this.updateCounter = this.updateCounter.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const res = await fetch("https://xkcd.vercel.app/?comic=latest");
    const xkcd = await res.json();
    this.setState({ xkcd, loading: "" });
  }

  updateCounter() {
    this.setState({ counter: this.state.counter + 1 });
  }

  render() {
    return (
      <div>
        <h3>You can even create React Apps 🚀</h3>
        <p onClick={this.updateCounter}>
          Like the classic react example counter: {this.state.counter}
        </p>
        {this.state.loading === LOADING ? (
          <p>
            <em>
              <strong>[ XKCD ]</strong>
            </em>
          </p>
        ) : (
          <XKCD {...this.state.xkcd} />
        )}
      </div>
    );
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="Using TypeScript" location={location}>
    <Seo title="Using TypeScript" />
    <h1>Gatsby supports TypeScript by default!</h1>
    <p>
      This means that you can create and write <em>.ts/.tsx</em> files for your
      pages, components etc. Please note that the <em>gatsby-*.js</em> files
      (like gatsby-node.js) currently don't support TypeScript yet.
    </p>
    <p>
      For type checking you'll want to install <em>typescript</em> via npm and
      run <em>tsc --init</em> to create a <em>tsconfig</em> file.
    </p>
    <p>
      You're currently on the page "{path}" which was built on{" "}
      {data.site.buildTime}.
    </p>
    <p>
      To learn more, head over to our{" "}
      <a href="https://www.gatsbyjs.com/docs/typescript/">
        documentation about TypeScript
      </a>
      .
    </p>
    <App />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default UsingTypescript;

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`;
